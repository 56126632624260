import '../veedu-spinner/veedu-spinner.css';

const VeeduSpinner = () => {

    return (
        <>
            <div className="d-flex justify-content-center align-items-center"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgb(255 255 255 / 38%)',
                    zIndex: 1000
                }}>
                <div className="loading">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </>
    )
};
export default VeeduSpinner;