import { propsWithBsClassName } from "react-bootstrap-typeahead/types/utils";
import HttpClientWrapper from "../../http-client-wrapper";
import { CommentLikePayload, CommentReplyLikePayload, FavouritePostPayload, PageFollowPayload, PagePostCommentPayload, PagePostCommentReplyPayload, PagePostLikePayload, PagePostTagFollowPayload, PagePostTagViewPayload, PageReportPayload, PostReportPayload, PostSharePayload } from "./professional-page-payload";

class ProfessionalPageApiService {

    [x: string]: any;

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getAllProfessionType = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/picklists/professionType/template');
            console.log("PropertyApi getAllProfessionType() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    getAllCallToActions = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/picklists/callToActions/template');
            console.log("PropertyApi getAllProfessionType() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }
    getAllProfessionalPostReport = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/picklists/professionalPostReport/template');
            console.log("PropertyApi getAllProfessionType() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }
    getAllProfessionalPageReport = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/picklists/professionalPageReport/template');
            console.log("PropertyApi getAllProfessionType() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }
    getProfessionalPageTemplateData = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPages/templates');
            console.log("PropertyApi getcountry() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }
    getCustomPlanTemplateData = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/customPlans/templates');
            console.log("PropertyApi getcountry() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    getProfessionaTypeListData = async (keyWord?: any) => {
        try {
            let data: any;
            if (keyWord) {

                data = await this.httpClientWrapper.get('/v1/picklists/searchProfessionTypes/' + '?professionTypes=' + keyWord);
            } else {
                data = await this.httpClientWrapper.get('/v1/picklists/searchProfessionTypes');
            }
            console.log("PropertyApi getcountry() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }
    getAllFollowersData = async (id: any, keyWord?: any) => {
        try {
            let data: any;
            if (keyWord) {
                data = await this.httpClientWrapper.get('/v1/professionalPages/followers/' + id + '?followersName=' + keyWord);
            } else {
                data = await this.httpClientWrapper.get('/v1/professionalPages/followers/' + id);
            }
            return data;
        } catch (error) {
            throw error;
        }
    }
    getProfessionalPageById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPages/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }
    getProfessionalPagePostTagById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPagePostTags/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getAllProfessionalPageData = async (url) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPages/flat' + url);
            return data;
        } catch (error) {
            throw error;
        }
    }


    getAllPostData = async (url) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPagePosts/flat' + url);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getAllTagData = async (url) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPagePostTags/tagFlat' + url);
            return data;
        } catch (error) {
            throw error;
        }
    }
    getAllnewPlanData = async (url) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/addNewPlan/flat' + url);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getPlanId = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/addNewPlan/' + id);
            console.log("PropertyApi getProperty() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }
    getProfessionalPageData = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPages/createdBy/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }
    getProfessionalPageId = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPages/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }
    getProfessionalPageServicesData = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPageServices/profession/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }

    saveProfessionalPage = async (formData: FormData) => {
        try {
            let data: any = await this.httpClientWrapper.postFormData('/v1/professionalPages', formData);
            return data;
        } catch (error) {
            throw error;
        }
    }
    saveProfessionalPostReport = async (reportTypeCode: string, payload: PostReportPayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/professionalPages/report/' + reportTypeCode, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    saveProfessionalPostShare = async (payload: PostSharePayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/professionalPagePosts/sharePost/', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    searchProfessionType = async (keyword: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/picklists/professionType/search/?keyword=' + encodeURIComponent(keyword));
            console.log("LocationApiService searchLocation() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }
    saveProfessionalPageReport = async (reportTypeCode: string, payload: PageReportPayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/professionalPages/report/' + reportTypeCode, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    updateProfessionalPage = async (id: any, formData: FormData) => {
        try {
            let data: any = await this.httpClientWrapper.putFormData('/v1/professionalPages/' + id, formData);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getProfessionTypeById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/picklists/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getCountryById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/countries/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }

    deleteProfessionalpagePost = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('/v1/professionalPagePosts/' + id);
            console.log("PropertyApi deleteProperty() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    deleteProfessionalPost = async (postId: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('/v1/professionalPagePosts/' + postId);
            console.log("PropertyApi deleteProperty() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }
    deleteProfessionalpage = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('/v1/professionalPages/' + id);
            console.log("PropertyApi deleteProperty() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    deleteProfessionalpagePostImage = async (postId: any, imageId: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('/v1/professionalPagePosts/' + postId + '/images/' + imageId);

            console.log("PropertyApi deleteProperty() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }


    uploadProfessionalPagePost = async (formData: FormData) => {
        try {
            let data: any = await this.httpClientWrapper.postFormData('/v1/professionalPagePosts', formData);
            return data;
        } catch (error) {
            throw error;
        }
    }

    updateProfessionalPagePost = async (id: any, formData: FormData) => {
        try {
            let data: any = await this.httpClientWrapper.putFormData('/v1/professionalPagePosts/' + id, formData);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getProfessionalPagePostData = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPagePosts/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }
    // getProfessionalPagePost = async (postId: any) => {
    //     try {
    //         let data: any = await this.httpClientWrapper.get('/v1/professionalPagePosts/' + postId);
    //         return data;
    //     } catch (error) {
    //         throw error;
    //     }
    // }
    getProfessionalPagePostDataList = async (userPageId: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPagePosts/followingPost/' + userPageId);
            return data;
        } catch (error) {
            throw error;
        }
    }

    saveProfessionalPagePostComment = async (payload: PagePostCommentPayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/professionalPageComments', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    saveProfessionalPagePostCommentReply = async (payload: PagePostCommentReplyPayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/professionalPageComments/reply', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getProfessionalPagePostCommentByPostId = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPageComments/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getProfessionalPagePostCommentReplyByCommentId = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPageComments/reply/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getProfessionalPagePostCommentReplyByParentId = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPageComments/reply/parentReplyId/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }

    saveProfessionalPagePostLike = async (payload: PagePostLikePayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/professionalPagePostLikes', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    switchProfessionalPage = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.put('/v1/professionalPages/switchProfPage/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }

    savePageFollow = async (payload: PageFollowPayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/professionalPages/follow', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    savePagePostTagFollow = async (payload: PagePostTagFollowPayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/professionalPagePostTags/follow', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    savePagePostTagView = async (payload: PagePostTagViewPayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/professionalPagePostTags/view', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    saveFavouritePost = async (payload: FavouritePostPayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/professionalPagePosts/favouritePost', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    saveCommentLike = async (payload: CommentLikePayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/professionalPageComments/commentLike', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    saveCommentReplyLike = async (payload: CommentReplyLikePayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/professionalPageComments/commentReplyLike', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    deleteComment = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('/v1/professionalPageComments/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }

    deleteCommentReply = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('/v1/professionalPageComments/reply/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }

    uploadPageProfileAndCoverImage = async (id: any, formData: FormData) => {
        try {
            let data: any = await this.httpClientWrapper.putFormData('/v1/professionalPages/uploadProfileAndCoverImage/' + id, formData);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getProfessionalPagePostTagTemplateData = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPagePosts/fetchAllPostTag');
            return data;
        } catch (error) {
            throw error;
        }
    }

    getTagPurchaseByTagId = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/professionalPagePostTags/purchaseTag/' + id);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getTagByTagName = async (tagName: any) => {
        try {
            let data: any = await this.httpClientWrapper.get(`/v1/professionalPagePostTags/getTagByTagName/${tagName}`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getRecommendedTagsByRecommendedType = async (recommendedType) => {
        try {
            let data: any = await this.httpClientWrapper.get(`/v1/professionalPagePostTags/fetchRecommendedTagsByRecommendedType` + recommendedType);
            return data;
        } catch (error) {
            throw error;
        }
    }

    saveProfessionalPageChat = async (formData: FormData) => {
        try {
            let data: any = await this.httpClientWrapper.postFormData('/v1/professionMessages', formData);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getUserStatus = async () => {
        try {
            let data: any = await this.httpClientWrapper.get(`/v1/professionMessages/user-status`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getChatMessages = async (url) => {
        try {
            let data: any = await this.httpClientWrapper.get(`/v1/professionMessages/messages` + url);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async downloadChatMedia(chatId, filename) {
        const response = await fetch(`/api/v1/professionMessages/downloadMedia/${chatId}/${filename}`, {
            method: 'GET',
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return response;
    }

}
export default ProfessionalPageApiService;