import "./professional-page-chat.css";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProfessionalPageMediaMessage = ({ media, handleImageClick }) => {

    return (
        <div className="d-flex" style={{ width: "100%", height: "40vh", cursor: "pointer" }} onClick={handleImageClick}>
            {media.mediaType === "IMAGE" ? (
                <img src={media.mediaUrl} alt="Chat" style={{ width: "100%", height: "100%", borderRadius: "10px", objectFit: "cover" }} />
            ) : (
                <div style={{ position: "relative" }}>
                    <video src={media.mediaUrl} style={{ width: "100%", height: "100%", borderRadius: "10px", objectFit: "cover" }} />
                    <FontAwesomeIcon
                        icon={faPlayCircle}
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            background: "rgba(0, 0, 0, 0.5)",
                            borderRadius: "50%",
                            padding: "5px",
                            fontSize: "xx-large",
                            color: 'white'
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default ProfessionalPageMediaMessage;