import { useEffect, useState } from "react";
import AuthApiService from "../../data/api/services/auth/auth-api-service";
import googleOneTap from 'google-one-tap';
import StorageService from "../../data/storage/storage-service";
import ApiConfig from "../../data/api/api-config";

const GoogleOneTapLogin = () => {

    const authService: AuthApiService = new AuthApiService();
    const apiConfig = new ApiConfig();
    const [baseUrl, setBaseUrl] = useState("");

    useEffect(() => {
        setBaseUrl(apiConfig.getBaseURL);
    }, []);

    useEffect(() => {
        console.log("apiConfig", baseUrl);
        if (!StorageService.isLoggedIn() && baseUrl) {
            const options = {
                client_id: '618065698598-5qvbvq6cupnnt47us14a231f7pbgbdcs.apps.googleusercontent.com',
                auto_select: false,
                cancel_on_tap_outside: false,
                context: 'signin',
            };

            googleOneTap(options, async (response) => {
                window.location.href = baseUrl + '/oauth2/authorization/google';
                console.log('response', response);
                // if (response && response.credential) {
                //     const base64Url = response.credential.split(".")[1];
                //     const base64 = base64Url.replace(/-/g, "+").replace(/-/g, "/");
                //     const jsonPayload = decodeURIComponent(atob(base64).split("").map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`).join(""));
                //     console.log('json', jsonPayload);
                //     const userData = JSON.parse(jsonPayload);
                //     console.log('userData', userData);
                //     let payload: AfterPaymentPayload = {
                //         userName: userData.email,
                //         logInType: 'GOOGLE',
                //         roleCode: 'ROLE_VIEW_USER'
                //     };
                //     await authService.afterPaymentLogIn(payload);
                //     navigate('/');
                // }
            });
        }
    })

    return (
        <></>
    )
};

export default GoogleOneTapLogin;