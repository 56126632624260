import "../profession-post/profession-post.css";
import { useEffect, useState } from "react";
import TelegramIcon from '@mui/icons-material/Telegram';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ProfessionPhotos from "../profession-photos/profession-photos";
import ProfessionVideos from "../profession-videos/profession-videos";
import ViewProfessionPost from "../view-profession-post/view-profession-post";
import ViewProfessionalPageFeed from "../view-professional-page-feed/view-professional-page-feed";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import StorageService from "../../../data/storage/storage-service";
import { Col, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFlag, faGear, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import { PageFollowPayload } from "../../../data/api/services/professional-page/professional-page-payload";
import ProfessionPageReport from "../profession-page-report/profession-page-report";
import AddProfessionPost from "../add-profession-post/add-profession-post";
import LoginPageDialog from "../../login/login-page-dialog";

const ProfessionPost = ({ id }: { id: number }) => {

  const professionalPageApiService = new ProfessionalPageApiService();
  let navigate: any = useNavigate();
  const userId = StorageService.getUserId();
  const [selectedOption, setSelectedOption] = useState('');
  const [data, setData] = useState<any>([]);
  const [hasFeedOption, setHasFeedOption] = useState(false);
  const professionalPageId = id;
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [reportShow, setReportShow] = useState(false);
  const reportClose = () => setReportShow(false);
  const [userPageData, setUserPageData] = useState<any>([]);
  const activePageItem = userPageData.find(item => item.activePage === true);
  const userPageId = activePageItem?.id;
  const [reportData, setReportData] = useState<any>([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showLogInDialog, setShowLogInDialog] = useState(false);
  const handleShowLogInDialog = () => setShowLogInDialog(true);
  const handleCloseLogInDialog = () => setShowLogInDialog(false);

  const handleAddPost = () => {
    setShow(true);
  };

  const handleFollowButtonClick = () => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      handleFollowClick();
    }
  };

  const handleFollowClick = () => {
    setIsFollowing((prevIsFollowing) => !prevIsFollowing);
    savePageFollow();
  };

  const showLoggedInConfirmDialog = () => {
    confirmAlert({
      title: 'Alert',
      message: 'You have to logIn.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { navigate('/login'); }
        },
        {
          label: 'No',
          onClick: () => { console.log('clicked no') }
        }
      ]
    });
  };

  const toggleDropdown = () => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      setDropdownVisible(!isDropdownVisible);
    }
  };

  const closeSubMenu = () => {
    if (isDropdownVisible) {
      setDropdownVisible(false);
    }
  };

  const handleDeleteClick = (id: any) => {
    setDropdownVisible(false);
    confirmAlert({
      title: "Delete",
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteProfessionalPage(id),
        },
        {
          label: "No",
          onClick: () => { }
        }
      ]
    });
  };

  const deleteProfessionalPage = async (id: any) => {
    try {
      if (id) {
        await professionalPageApiService.deleteProfessionalpage(id);
        return;
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeSubMenu);

    return () => {
      document.removeEventListener('click', closeSubMenu);
    };
  }, [isDropdownVisible]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    window.scrollTo(0, 0);
  };

  const getProfessionalPageDetails = async () => {
    try {
      const userId = StorageService.getUserId();
      console.log(userId);
      if (userId) {
        let res = await professionalPageApiService.getProfessionalPageData(userId);
        setUserPageData(res);
        const activePageItem = res.find(item => item.activePage === true);
        const userPageId = activePageItem?.id;
        getProfessionalPageById(userPageId);
      } else {
        getProfessionalPageById('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProfessionalPageById = async (userPageId) => {
    try {
      let res = await professionalPageApiService.getProfessionalPageById(professionalPageId);
      const feedOptionExists = res.id === userPageId;
      setHasFeedOption(feedOptionExists);
      setSelectedOption(feedOptionExists ? 'feed' : 'post');
      setData(res);
    } catch (error) {
      console.log(error);
    }
  };
  const handleReport = (item) => {
    setReportData(item);
    setReportShow(true);
    setSelectedItemIndex(null);
  };
  const savePageFollow = async () => {
    try {
      let payload: PageFollowPayload = {
        followingPageId: professionalPageId,
        userPageId: userPageId
      };
      await professionalPageApiService.savePageFollow(payload);
      getProfessionalPageDetails();
    } catch (error) {
      console.log(error)
    }
  }

  const handleEditProfessionalPage = () => {
    navigate('/update-professional-page', { state: { id } });
  }

  useEffect(() => {
    getProfessionalPageDetails();
  }, [professionalPageId]);

  useEffect(() => {
    if (data) {
      const isFollowing = data?.professionalPageFollowDataList?.some((follow) => {
        console.log('following:', follow.userPageId, 'followedBy:', userPageId);
        return follow.userPageId == userPageId;
      });
      console.log("Is Liked:", isFollowing);
      setIsFollowing(isFollowing);
    }
  }, [data, userPageId]);

  return <>
    <Row className="total-color d-flex justify-content-between py-2"
      style={{ position: 'sticky', top: '75px', zIndex: '2' }}>
      <Col md={6} className="d-flex align-items-center" id="radioButtons">
        {hasFeedOption && (
          <div className="p-2 mx-2">
            <input type="radio" name="option" id="feed" value="feed" checked={selectedOption === 'feed'}
              onChange={handleOptionChange} />
            <label htmlFor="feed" className="font-color">Feed</label>
          </div>
        )}
        <div className="p-2 mx-2">
          <input type="radio" name="option" id="post" value="post" checked={selectedOption === 'post'}
            onChange={handleOptionChange} />
          <label htmlFor="post" className="font-color">Post</label>
        </div>
        <div className="p-2 mx-2">
          <input type="radio" name="option" id="photos" value="photos" checked={selectedOption === 'photos'}
            onChange={handleOptionChange} />
          <label htmlFor="photos" className="font-color">Photos</label>
        </div>
        <div className="p-2 mx-2">
          <input type="radio" name="option" id="videos" value="videos" checked={selectedOption === 'videos'}
            onChange={handleOptionChange} />
          <label htmlFor="videos" className="font-color">Videos</label>
        </div>
      </Col>
      <Col md={6} className="d-flex align-items-center justify-content-center">
        <Col md={11} xs={11} className="d-flex justify-content-between">
          {data.id != userPageId && (
            <>
              <Col md={6} xs={6} className="px-2 d-flex justify-content-center me-1 page-follow-button"
                style={{ cursor: "pointer" }}
                onClick={handleFollowButtonClick}>
                <button className="d-flex align-items-center view-page-button">
                  {data?.professionalPageFollowDataList?.some((follow) => follow.userPageId == userPageId) ? (
                    <><DoneIcon className="page-icon-rotation" fontSize="small" />Following</>
                  ) : (
                    <><AddIcon className="page-icon-rotation" fontSize="small" />Follow</>
                  )}
                </button>
              </Col>
              <Col md={6} xs={6} className="px-2 d-flex justify-content-center page-send-message-button"
                style={{ cursor: "pointer" }}
                onClick={() => navigate('/chat', { state: { data } })}>
                <button className="d-flex align-items-center view-page-button"> Chat <TelegramIcon fontSize="small" className="page-icon-rotation" /></button>
              </Col>
            </>
          )}
          {data.id == userPageId && (
            <>
              <Col md={6} xs={2} className="px-2 d-flex justify-content-end me-1">
                <button className="view-page-button"
                  style={{ border: '3px solid #42A65E', borderRadius: '5px' }}
                  onClick={handleAddPost}>
                  <AddIcon className="page-icon-rotation" style={{ color: '#42A65E' }} />
                </button>
              </Col>
              <Col md={6} xs={10} className="px-2 d-flex justify-content-center page-send-message-button"
                style={{ cursor: "pointer" }}
                onClick={() => navigate('/chat', { state: { data } })}>
                <button className="d-flex align-items-center view-page-button"> Chat <TelegramIcon fontSize="small" className="page-icon-rotation" /></button>
              </Col>
            </>
          )}
        </Col>
        <Col md={1} xs={1} className="d-flex justify-content-end" onClick={(e) => e.stopPropagation()}>
          <span onClick={() => toggleDropdown()}>
            <MoreVertIcon />
          </span>
          {data.createdBy == userId && (
            <div className={`page-dropdown-content ${isDropdownVisible ? 'show' : ''}`}>
              <div className="mb-2" onClick={() => handleEditProfessionalPage()}>
                <FontAwesomeIcon icon={faEdit} className="me-2" />
                Edit Page
              </div>
              {data.id != userPageId && (
                <div className="mb-2" onClick={() => handleDeleteClick(data.id)}>
                  <FontAwesomeIcon icon={faTrash} className="me-2" />
                  Delete Page
                </div>
              )}
              <div>
                <FontAwesomeIcon icon={faGear} className="me-2" />
                Settings
              </div>
            </div>
          )}
          {data.createdBy != userId && (
            <div className={`page-dropdown-content ${isDropdownVisible ? 'show' : ''}`}>
              <div onClick={() => handleReport(id)}>
                <FontAwesomeIcon icon={faFlag} className="me-2" />
                Report
              </div>
            </div>
          )}
        </Col>
      </Col>
    </Row >
    <div className="bg-light white">
      {selectedOption === 'feed' && <ViewProfessionalPageFeed professionalPageId={professionalPageId} />}
      {selectedOption === 'post' && <ViewProfessionPost professionalPageId={professionalPageId} />}
      {selectedOption === 'photos' && <ProfessionPhotos professionalPageId={professionalPageId} />}
      {selectedOption === 'videos' && <ProfessionVideos professionalPageId={professionalPageId} />}

      <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
        <AddProfessionPost handleClose={handleClose} />
      </Modal>
      <Modal size="lg" show={reportShow} onHide={reportClose}>
        <ProfessionPageReport id={reportData} handleClose={reportClose} />
      </Modal>
      <Modal show={showLogInDialog} onHide={handleCloseLogInDialog} backdrop="static"
        centered className="login-page-dialog-modal">
        <LoginPageDialog handleClose={handleCloseLogInDialog} />
      </Modal>
    </div>
  </>
}

export default ProfessionPost;


