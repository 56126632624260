import "./professional-page-chat.css";
import { faFileAlt, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";

const ProfessionalPageDocumentMessage = ({ chatId, media, formatFileSize }) => {

    const professionalPageApiService = new ProfessionalPageApiService();

    const handleDownload = async (filename) => {
        try {
            let response = await professionalPageApiService.downloadChatMedia(chatId, filename);
            if (response && response.blob) {
                const blob = await response.blob();
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            } else {
                console.error("Invalid response or blob function missing.");
            }
        } catch (error) {
            console.error("Download failed:", error);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                backgroundColor: "#f1f1f1",
                borderRadius: "10px",
                gap: "10px",
            }}
        >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: "2rem", color: "#666" }} />
                <div className="text-start" style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontSize: "1rem", color: "#333", display: '-webkit-box', WebkitBoxOrient: "vertical", WebkitLineClamp: 2, overflow: "hidden" }}>
                        {media.mediaUrl?.split("/").pop() || "Document"}
                    </span>
                    {media.mediaSize && (
                        <span style={{ fontSize: "0.875rem", color: "#666" }}>
                            {media.mediaUrl.split(".").pop().toUpperCase()} . {formatFileSize(media.mediaSize)}
                        </span>
                    )}
                </div>
            </div>
            <FontAwesomeIcon icon={faDownload} style={{ fontSize: "1.5rem", color: "#666", cursor: "pointer" }}
                onClick={() => handleDownload(media.mediaUrl.split("/").pop())} />
        </div>
    )
}
export default ProfessionalPageDocumentMessage;