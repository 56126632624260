import "./veedu-sidenav.css"
import { useEffect, useState } from "react";
import VeeduLogo from '../../assets/otp-page-logo.png'
import Avatar from '../../assets/profile-pic.jpeg'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import StorageService from "../../data/storage/storage-service";
import ProfessionalPageApiService from "../../data/api/services/professional-page/professional-page-api-service";
import { useNavigate } from "react-router-dom";

const menuItems = [
    { name: "Home", icon: <HomeOutlinedIcon className="veedu-sidenav__icon fs-1" />, link: "/" },
    { name: "Search", icon: <SearchOutlinedIcon className="veedu-sidenav__icon fs-1" />, link: "#" },
];

const dropdownMenus = [
    {
        name: "Pages",
        icon: <FormatListBulletedOutlinedIcon className="veedu-sidenav__icon fs-1" />,
        // subItems: ["My Pages", "Professionals", "Tags", "Floor Plans", "Plan Status"],
        subItems: [
            {
                subItemName: "My Pages",
                subItemLink: "/my-professional-pages"
            },
            {
                subItemName: "Professionals",
                subItemLink: "/professionals"
            },
            {
                subItemName: "Tags",
                subItemLink: "/tags"
            },
            {
                subItemName: "Floor Plans",
                subItemLink: "/plans"
            },
            {
                subItemName: "Plan Status",
                subItemLink: "/plan-status"
            }
        ],
    },
    {
        name: "Add New",
        icon: <AddOutlinedIcon className="veedu-sidenav__icon fs-1" />,
        // subItems: ["Add Property", "Create Professional Page"],
        subItems: [
            {
                subItemName: "Add Property",
                subItemLink: "/add-property"
            },
            {
                subItemName: "Create Professional Page",
                subItemLink: "/create-professional-page"
            }
        ],
    },
];

const VeeduSideNav = () => {

    let navigate = useNavigate();
    const professionalPageApiService = new ProfessionalPageApiService();
    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData?.find(item => item.activePage === true);
    const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);

    const toggleSubMenu = (menu: string) => {
        setActiveSubMenu(activeSubMenu === menu ? null : menu);
    };

    const closeSubMenu = () => {
        setActiveSubMenu(null);
    };

    useEffect(() => {
        getProfessionalPageDetails();
    })

    const getProfessionalPageDetails = async () => {
        try {
            if (userPageData.length == 0) {
                const userId = StorageService.getUserId();
                console.log(userId);
                if (userId != null) {
                    let res = await professionalPageApiService.getProfessionalPageData(userId);
                    setUserPageData(res);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <nav id="veedu-sidenav" onMouseLeave={closeSubMenu}>
            <ul className="veedu-sidenav__list">
                {/* Sidebar Logo */}
                <li className="veedu-sidenav__logo" onClick={() => navigate('/')}>
                    <img src={VeeduLogo} height={55} alt="Veedu Logo" />
                    <span className="veedu-sidenav__logo-text fs-3"><div>Veedu</div></span>
                </li>

                <div className="veedu-sidenav__menu">
                    {/* Main Menu Items */}
                    {menuItems.map(({ name, icon, link }) => (
                        <li key={name} className="veedu-sidenav__menu-item">
                            <a href={link} className="veedu-sidenav__menu-link">
                                {icon}
                                <span className="veedu-sidenav__menu-link-text"><div>{name}</div></span>
                            </a>
                        </li>
                    ))}

                    {/* Dropdown Menus */}
                    {dropdownMenus.map(({ name, icon, subItems }) => (
                        <li key={name} className="veedu-sidenav__dropdown-menu-item">
                            <a
                                className="veedu-sidenav__dropdown-menu-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleSubMenu(name);
                                }}
                            >
                                {icon}
                                <span className="veedu-sidenav__dropdown-menu-link-text">
                                    <div>{name}<ArrowDropDownOutlinedIcon /></div>
                                </span>
                            </a>
                            <ul className={`veedu-sidenav__sub-menu ${activeSubMenu === name ? "veedu-sidenav__sub-menu--show" : ""}`}>
                                {/* <ul className="veedu-sidenav__sub-menu"> */}
                                <div>
                                    {subItems.map(({ subItemName, subItemLink }) => (
                                        <li key={subItemName} className="veedu-sidenav__dropdown-sub-menu-item">
                                            <a href={subItemLink} className="veedu-sidenav__dropdown-sub-menu-link">
                                                <span className="veedu-sidenav__dropdown-sub-menu-link-text ps-2">{subItemName}</span>
                                            </a>
                                        </li>
                                    ))}
                                </div>
                            </ul>
                        </li>
                    ))}

                    {/* Sign In */}
                    {!userPageData &&
                        <li className="veedu-sidenav__menu-item">
                            <a href="#" className="veedu-sidenav__menu-link">
                                <LoginOutlinedIcon className="veedu-sidenav__icon fs-1" />
                                <span className="veedu-sidenav__menu-link-text"><div>Sign In</div></span>
                            </a>
                        </li>
                    }
                </div>

                {activePageItem &&
                    <li className="veedu-sidenav__profile">
                        <img src={activePageItem && activePageItem?.profileImageUrl ? activePageItem?.profileImageUrl : Avatar}
                            height={50} alt="Profile Pic" style={{ borderRadius: '50%', aspectRatio: '1/1', objectFit: 'cover' }} />
                        <span className="veedu-sidenav__profile-text"><div>My Profile</div></span>
                    </li>
                }
            </ul>
        </nav>
    );
};

export default VeeduSideNav;