import { useEffect, useRef, useState } from "react";
import "./veedu-customized-video-tag.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay, faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";

const VeeduCustomizedVideoTag = ({ mediaUrl, onPause, onPlay }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [volume, setVolume] = useState(1);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        const videoElement = videoRef.current;
        const handlePlay = () => {
            setIsPlaying(true);
            onPlay?.(videoElement);
        };
        const handlePause = () => {
            setIsPlaying(false);
            onPause?.();
        };
        videoElement.addEventListener('play', handlePlay);
        videoElement.addEventListener('pause', handlePause);
        return () => {
            videoElement.removeEventListener('play', handlePlay);
            videoElement.removeEventListener('pause', handlePause);
        };
    }, [onPause, onPlay]);

    const togglePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
    };

    const toggleMute = () => {
        videoRef.current.muted = !isMuted;
        setIsMuted(!isMuted);
    };

    const handleVolumeChange = (e) => {
        const newVolume = e.target.value;
        videoRef.current.volume = newVolume;
        setVolume(newVolume);
        if (newVolume === "0") setIsMuted(true);
        else setIsMuted(false);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(videoRef.current.currentTime);
    };

    const handleSeek = (e) => {
        const newTime = e.target.value;
        videoRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    return (
        <div className="veedu_custom_video_wrapper">
            <video
                ref={videoRef}
                className="veedu_custom_video_tag-video"
                src={mediaUrl}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={(e) =>
                    setDuration((e.target as HTMLMediaElement).duration)
                }
                onClick={togglePlayPause}
            />
            <div className="veedu_custom_video_tag-controls">
                <span
                    onClick={togglePlayPause}
                    className="veedu_custom_video_tag-button"
                >
                    {isPlaying ? (
                        <FontAwesomeIcon icon={faPause} />
                    ) : (
                        <FontAwesomeIcon icon={faPlay} />
                    )}
                </span>
                <div className="veedu_custom_video_tag-seek-container">
                    <input
                        id="veedu_custom_video_tag-progress"
                        type="range"
                        min="0"
                        max={duration}
                        step="0.1"
                        value={currentTime}
                        onChange={handleSeek}
                        className="veedu_custom_video_tag-seek"
                    />
                    <div
                        className="veedu_custom_video_tag-progress"
                        style={{ width: `${(currentTime / duration) * 100}%` }}
                    />
                </div>
                <span className="veedu_custom_video_tag-time">
                    {Math.floor(currentTime / 60)}:
                    {("0" + Math.floor(currentTime % 60)).slice(-2)} /{" "}
                    {Math.floor(duration / 60)}:
                    {("0" + Math.floor(duration % 60)).slice(-2)}
                </span>
                <span
                    onClick={toggleMute}
                    className="veedu_custom_video_tag-button"
                >
                    {isMuted ? (
                        <FontAwesomeIcon icon={faVolumeMute} />
                    ) : (
                        <FontAwesomeIcon icon={faVolumeUp} />
                    )}
                </span>
                <div className="veedu_custom_video_tag-volume-container">
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.1"
                        value={volume}
                        onChange={handleVolumeChange}
                        className="veedu_custom_video_tag-volume"
                    />
                    <div
                        className="veedu_custom_video_tag-volume-progress"
                        style={{ width: `${volume * 100}%` }}
                    />
                </div>
            </div>
        </div>
    );
};

export default VeeduCustomizedVideoTag;